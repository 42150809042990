import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@/hooks/useAuth';

type Props = {
  element: ReactElement;
};

export const AuthRoute = ({ element }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isFetched, isLoading, error } = useAuth();

  useEffect(() => {
    if (error || (isFetched && !user && !isLoading)) {
      navigate('/login', { state: { redirect: location.pathname + location.search } });
    }
  }, [isFetched, user, error]);

  return user ? (
    element
  ) : (
    <div className={'relative h-full w-full'}>
      <div className={'loading absolute left-1/2 mx-auto mt-40 block w-[150px] -translate-x-1/2'} />
    </div>
  );
};
