import React from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'ghost'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'neutral';
  variant?: 'outline' | 'ghost' | 'link';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  shape?: 'circle' | 'square';
  circle?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = 'button',
  color = 'primary',
  variant = '',
  size = 'md',
  disabled = false,
  className = '',
  shape,
  loading,
  ...props
}) => {
  const baseClass = 'btn';
  const colorClass = variant === 'link' ? `link-${color}` : `btn-${color}`;
  const variantClass = variant ? `btn-${variant}` : '';
  const sizeClass = `btn-${size}`;
  const disabledClass = disabled || loading ? 'btn-disabled' : '';
  const shapeClass = `btn-${shape}`;

  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(
        `${baseClass} ${colorClass} ${sizeClass} ${disabledClass} ${shapeClass} ${variantClass}`,
        className,
      )}
      disabled={disabled || loading}
      {...props}
    >
      {children}
      {loading && <span className="loading ml-2" />}
    </button>
  );
};

export default Button;
