import { Outlet } from 'react-router-dom';

import { NavBar } from '@/components/layouts/NavigationLayout/components/NavBar';
import { SideDrawer } from '@/components/layouts/NavigationLayout/components/SideDrawer/SideDrawer';
import { NavigationLayoutProvider } from '@/components/layouts/NavigationLayout/context/navigationLayoutContext';

export const NavigationLayout = () => {
  return (
    <NavigationLayoutProvider>
      <div className={'flex h-screen w-screen flex-row overflow-hidden'}>
        <SideDrawer />
        <div className={'flex w-full flex-1 flex-grow flex-col overflow-hidden'}>
          <NavBar />
          <Outlet />
        </div>
      </div>
    </NavigationLayoutProvider>
  );
};
