import { ReactNode } from 'react';

interface Props {
  list: (string | ReactNode)[];
  size: 'sm' | 'xs';
}

export const ListFormatter = ({ list, size = 'xs' }: Props) => {
  return (
    <ul className="list-inside list-disc font-[poppins]">
      {list.map((item, i) => (
        <li key={typeof item === 'string' ? item : i} className={`${'text-' + size}`}>
          {item}
        </li>
      ))}
    </ul>
  );
};
