import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label?: string;
  required?: boolean;
  labelAction?: ReactNode;
}

export const InputLabel = ({ label, required, className, labelAction, ...props }: Props) => {
  return (
    <label
      className={twMerge('flex items-center gap-2 pl-3 font-["forum"] font-semibold', className)}
      {...props}
    >
      <span>
        {label} {required && <span className="text-error">*</span>}
      </span>{' '}
      {labelAction}
    </label>
  );
};
