import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  children?: ReactNode;
  className?: HTMLDivElement['className'];
}

export const SectionWithHeader = ({ children, title, className }: Props) => {
  return (
    <div className={twMerge('h-max w-full rounded-lg border border-gray-500 bg-white', className)}>
      <h5
        className={'rounded-t-lg bg-neutral px-4 py-2 text-lg font-bold tracking-wider text-white'}
      >
        {title}
      </h5>
      {children}
    </div>
  );
};
