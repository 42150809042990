import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

// Import Quill styling
interface QuillEditorProps {
  value: string;
  disabled?: boolean;
  onChange: (content: string) => void;
  className?: HTMLDivElement['className'];
  required?: boolean;
}

export const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  disabled,
  className,
  required,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null); // Local ref for the Quill instance
  const onChangeRef = useRef(onChange);
  const isFirstRender = useRef(true);

  useLayoutEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const editorContainer = container.appendChild(document.createElement('div'));
    quillRef.current = new Quill(editorContainer, { theme: 'snow' });

    // Initialize editor content
    quillRef.current.root.innerHTML = value;

    // Sync changes back to the parent component
    quillRef.current.on(Quill.events.TEXT_CHANGE, () => {
      const content = quillRef.current!.root.innerHTML;
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      onChangeRef.current(content);
    });

    return () => {
      quillRef.current = null;
      container.innerHTML = '';
    };
  }, []);

  // Update editor content when `value` prop changes
  useEffect(() => {
    if (quillRef.current && quillRef.current.root.innerHTML !== value) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  useEffect(() => {
    // Disable/enable the editor based on the `disabled` prop
    if (disabled) {
      quillRef?.current?.disable();
    } else {
      quillRef?.current?.enable();
    }
  }, [disabled, quillRef.current]);

  return (
    <>
      <div ref={containerRef} className={twMerge('relative min-h-[200px]', className)} />
      {required && !quillRef.current?.getText()?.trim() ? (
        <input type={'checkbox'} required className={'absolute right-1/2 top-1/3 opacity-0'} />
      ) : null}
    </>
  );
};

QuillEditor.displayName = 'QuillEditor';
