import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTimeISO: { input: any; output: any };
};

export type AssociationInput = {
  entity_id: Scalars['String']['input'];
  entity_type: KanbanAssociationTypeEnum;
};

export type CreateKanbanCardInput = {
  associations: Array<AssociationInput>;
  board_type: KanbanBoardTypeEnum;
  status: Scalars['String']['input'];
};

export type FollowupKanbanCard = {
  board_type: Scalars['String']['output'];
  created_at: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  interview: Interview;
  person_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
};

export type FollowupKanbanCardInputs = {
  interviewer_id?: InputMaybe<Scalars['String']['input']>;
};

export type Interview = {
  created_at: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  deferred_to?: Maybe<Scalars['DateTimeISO']['output']>;
  follow_up_notes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  interviewer: User;
  interviewer_id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  person: Person;
  person_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
};

export type InterviewFilterInput = {
  interviewerId?: InputMaybe<Scalars['String']['input']>;
  interviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  personIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InterviewStats = {
  interviewLoss: Scalars['Float']['output'];
  interviewWins: Scalars['Float']['output'];
};

/** The entity type of the kanban card association, the table name. */
export enum KanbanAssociationTypeEnum {
  Interviews = 'interviews',
  Persons = 'persons',
  Users = 'users',
}

/** The board type of the kanban card. */
export enum KanbanBoardTypeEnum {
  FollowUpApplicants = 'follow_up_applicants',
}

export type LoggedInUser = {
  auth_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type Mutation = {
  createKanbanCards: FollowupKanbanCard;
  createUser: User;
  deleteUser: Scalars['Boolean']['output'];
  patchFollowupKanbanCards: FollowupKanbanCard;
  patchInterview: Interview;
  patchPerson: Person;
  updateUser: User;
};

export type MutationCreateKanbanCardsArgs = {
  payload: CreateKanbanCardInput;
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPatchFollowupKanbanCardsArgs = {
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
};

export type MutationPatchInterviewArgs = {
  id: Scalars['String']['input'];
  payload: PatchInterview;
};

export type MutationPatchPersonArgs = {
  id: Scalars['String']['input'];
  payload: PatchPerson;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type PatchFollowupKanbanCardInput = {
  status: Scalars['String']['input'];
};

export type PatchInterview = {
  deferred_to?: InputMaybe<Scalars['DateTimeISO']['input']>;
  follow_up_notes?: InputMaybe<Scalars['String']['input']>;
  interviewer_id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type PatchPerson = {
  age_matching_preference?: InputMaybe<Scalars['String']['input']>;
  application_age_range?: InputMaybe<Scalars['String']['input']>;
  application_dinner_guest?: InputMaybe<Scalars['String']['input']>;
  archetype?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dietary_preferences?: InputMaybe<Scalars['String']['input']>;
  dietary_preferences_note?: InputMaybe<Scalars['String']['input']>;
  dinner_persona?: InputMaybe<Scalars['String']['input']>;
  extraversion?: InputMaybe<Scalars['String']['input']>;
  match_after_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  membership_status?: InputMaybe<Scalars['String']['input']>;
  rating_arts_culture?: InputMaybe<Scalars['String']['input']>;
  rating_business?: InputMaybe<Scalars['String']['input']>;
  rating_health_wellness?: InputMaybe<Scalars['String']['input']>;
  rating_history?: InputMaybe<Scalars['String']['input']>;
  rating_literature?: InputMaybe<Scalars['String']['input']>;
  rating_music_film?: InputMaybe<Scalars['String']['input']>;
  rating_philosophy?: InputMaybe<Scalars['String']['input']>;
  rating_politics_events?: InputMaybe<Scalars['String']['input']>;
  rating_spirituality_religion?: InputMaybe<Scalars['String']['input']>;
  rating_sports_recreation?: InputMaybe<Scalars['String']['input']>;
  rating_tech_science?: InputMaybe<Scalars['String']['input']>;
  risk_tolerance?: InputMaybe<Scalars['String']['input']>;
  score_conversation_focus?: InputMaybe<Scalars['Float']['input']>;
  score_energy?: InputMaybe<Scalars['Float']['input']>;
  score_experience?: InputMaybe<Scalars['Float']['input']>;
  score_extraversion?: InputMaybe<Scalars['Float']['input']>;
  score_intention_focus?: InputMaybe<Scalars['Float']['input']>;
  score_overall?: InputMaybe<Scalars['Float']['input']>;
  score_slope?: InputMaybe<Scalars['Float']['input']>;
  score_transactional?: InputMaybe<Scalars['Float']['input']>;
  score_vulnerability?: InputMaybe<Scalars['Float']['input']>;
  score_work_life?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
};

export type Person = {
  access_requirements?: Maybe<Scalars['String']['output']>;
  accomplishments?: Maybe<Scalars['String']['output']>;
  age_matching_preference?: Maybe<Scalars['String']['output']>;
  airtable_id?: Maybe<Scalars['String']['output']>;
  application_age_range?: Maybe<Scalars['String']['output']>;
  application_dinner_guest?: Maybe<Scalars['String']['output']>;
  archetype?: Maybe<Scalars['String']['output']>;
  area_of_study?: Maybe<Scalars['String']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  connection_expectation?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  credit_balance?: Maybe<Scalars['Float']['output']>;
  date_of_birth?: Maybe<Scalars['Date']['output']>;
  dietary_preferences?: Maybe<Scalars['String']['output']>;
  dietary_preferences_note?: Maybe<Scalars['String']['output']>;
  dinner_persona?: Maybe<Scalars['String']['output']>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  extraversion?: Maybe<Scalars['String']['output']>;
  family_status?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  has_children?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  interests?: Maybe<Scalars['String']['output']>;
  is_ambassador?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  match_after_date?: Maybe<Scalars['Date']['output']>;
  membership_charge_period?: Maybe<Scalars['String']['output']>;
  membership_started_at?: Maybe<Scalars['Date']['output']>;
  membership_status?: Maybe<Scalars['String']['output']>;
  nomination_code?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  rating_arts_culture?: Maybe<Scalars['String']['output']>;
  rating_business?: Maybe<Scalars['String']['output']>;
  rating_health_wellness?: Maybe<Scalars['String']['output']>;
  rating_history?: Maybe<Scalars['String']['output']>;
  rating_literature?: Maybe<Scalars['String']['output']>;
  rating_music_film?: Maybe<Scalars['String']['output']>;
  rating_philosophy?: Maybe<Scalars['String']['output']>;
  rating_politics_events?: Maybe<Scalars['String']['output']>;
  rating_spirituality_religion?: Maybe<Scalars['String']['output']>;
  rating_sports_recreation?: Maybe<Scalars['String']['output']>;
  rating_tech_science?: Maybe<Scalars['String']['output']>;
  referred_by_person?: Maybe<Person>;
  referred_by_person_id?: Maybe<Scalars['String']['output']>;
  referred_by_text?: Maybe<Scalars['String']['output']>;
  risk_tolerance?: Maybe<Scalars['String']['output']>;
  score_conversation_focus?: Maybe<Scalars['Float']['output']>;
  score_energy?: Maybe<Scalars['Float']['output']>;
  score_experience?: Maybe<Scalars['Float']['output']>;
  score_extraversion?: Maybe<Scalars['Float']['output']>;
  score_intention_focus?: Maybe<Scalars['Float']['output']>;
  score_overall?: Maybe<Scalars['Float']['output']>;
  score_slope?: Maybe<Scalars['Float']['output']>;
  score_transactional?: Maybe<Scalars['Float']['output']>;
  score_vulnerability?: Maybe<Scalars['Float']['output']>;
  score_work_life?: Maybe<Scalars['Float']['output']>;
  secondary_email?: Maybe<Scalars['String']['output']>;
  sexual_orientation?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  test_account?: Maybe<Scalars['Boolean']['output']>;
  three_words?: Maybe<Scalars['String']['output']>;
  time_in_city?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Date']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  value_expectation?: Maybe<Scalars['String']['output']>;
  work_description?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  getFollowupKanbanCards: Array<FollowupKanbanCard>;
  getInterviews: Array<Interview>;
  getLoggedInUser: LoggedInUser;
  getPerson: Person;
  getRoles: Array<Role>;
  getStats: Stats;
  getUsers: Array<User>;
};

export type QueryGetFollowupKanbanCardsArgs = {
  filters: FollowupKanbanCardInputs;
};

export type QueryGetInterviewsArgs = {
  filters: InterviewFilterInput;
};

export type QueryGetPersonArgs = {
  id: Scalars['ID']['input'];
};

export type Role = {
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type Stats = {
  interviewStats: InterviewStats;
};

export type User = {
  auth_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type UserCreateInput = {
  auth_id?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rolesToAdd?: InputMaybe<Array<Scalars['Int']['input']>>;
  rolesToRemove?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GetInterviewsQueryVariables = Exact<{
  filters: InterviewFilterInput;
}>;

export type GetInterviewsQuery = {
  getInterviews: Array<{
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
    person: {
      id: string;
      accomplishments?: string | null;
      access_requirements?: string | null;
      age_matching_preference?: string | null;
      airtable_id?: string | null;
      application_age_range?: string | null;
      application_dinner_guest?: string | null;
      archetype?: string | null;
      avatar_path?: string | null;
      city?: string | null;
      connection_expectation?: string | null;
      credit_balance?: number | null;
      created_at?: any | null;
      date_of_birth?: any | null;
      dietary_preferences?: string | null;
      dietary_preferences_note?: string | null;
      dinner_persona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      family_status?: string | null;
      first_name?: string | null;
      gender?: string | null;
      has_children?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      is_ambassador?: boolean | null;
      last_name?: string | null;
      membership_charge_period?: string | null;
      membership_started_at?: any | null;
      membership_status?: string | null;
      nomination_code?: string | null;
      phone_number?: string | null;
      primary_email?: string | null;
      rating_arts_culture?: string | null;
      rating_business?: string | null;
      rating_health_wellness?: string | null;
      rating_history?: string | null;
      rating_literature?: string | null;
      rating_music_film?: string | null;
      rating_philosophy?: string | null;
      rating_politics_events?: string | null;
      rating_sports_recreation?: string | null;
      rating_spirituality_religion?: string | null;
      rating_tech_science?: string | null;
      referred_by_person_id?: string | null;
      referred_by_text?: string | null;
      risk_tolerance?: string | null;
      score_conversation_focus?: number | null;
      score_energy?: number | null;
      score_experience?: number | null;
      score_extraversion?: number | null;
      score_intention_focus?: number | null;
      score_overall?: number | null;
      score_slope?: number | null;
      score_transactional?: number | null;
      score_vulnerability?: number | null;
      score_work_life?: number | null;
      secondary_email?: string | null;
      sexual_orientation?: string | null;
      status?: string | null;
      stripe_customer_id?: string | null;
      test_account?: boolean | null;
      three_words?: string | null;
      time_in_city?: string | null;
      updated_at?: any | null;
      user_id?: string | null;
      value_expectation?: string | null;
      work_description?: string | null;
      referred_by_person?: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      } | null;
    };
    interviewer: {
      auth_id?: string | null;
      email: string;
      name: string;
      id: string;
      roles: Array<{ id: number; name: string }>;
    };
  }>;
};

export type PatchInterviewMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchInterview;
}>;

export type PatchInterviewMutation = {
  patchInterview: {
    id: string;
    date: any;
    deferred_to?: any | null;
    interviewer_id: string;
    person_id: string;
    follow_up_notes?: string | null;
    status: string;
    notes?: string | null;
    created_at: any;
    updated_at: any;
  };
};

export type CreateKanbanCardMutationVariables = Exact<{
  payload: CreateKanbanCardInput;
}>;

export type CreateKanbanCardMutation = { createKanbanCards: { id: string } };

export type GetFollowupKanbanCardsQueryVariables = Exact<{
  filters: FollowupKanbanCardInputs;
}>;

export type GetFollowupKanbanCardsQuery = {
  getFollowupKanbanCards: Array<{
    id: string;
    status: string;
    created_at: any;
    updated_at: any;
    interview: {
      id: string;
      person: {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
        city?: string | null;
        primary_email?: string | null;
      };
      interviewer: { id: string; name: string };
    };
  }>;
};

export type PatchFollowupKanbanCardMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
}>;

export type PatchFollowupKanbanCardMutation = { patchFollowupKanbanCards: { id: string } };

export type PatchPersonMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchPerson;
}>;

export type PatchPersonMutation = { patchPerson: { id: string } };

export type PersonFragmentFragment = {
  id: string;
  accomplishments?: string | null;
  access_requirements?: string | null;
  age_matching_preference?: string | null;
  airtable_id?: string | null;
  application_age_range?: string | null;
  application_dinner_guest?: string | null;
  archetype?: string | null;
  avatar_path?: string | null;
  city?: string | null;
  connection_expectation?: string | null;
  credit_balance?: number | null;
  created_at?: any | null;
  date_of_birth?: any | null;
  dietary_preferences?: string | null;
  dietary_preferences_note?: string | null;
  dinner_persona?: string | null;
  ethnicity?: string | null;
  extraversion?: string | null;
  family_status?: string | null;
  first_name?: string | null;
  gender?: string | null;
  has_children?: boolean | null;
  industry?: string | null;
  interests?: string | null;
  is_ambassador?: boolean | null;
  last_name?: string | null;
  membership_charge_period?: string | null;
  membership_started_at?: any | null;
  membership_status?: string | null;
  nomination_code?: string | null;
  phone_number?: string | null;
  primary_email?: string | null;
  rating_arts_culture?: string | null;
  rating_business?: string | null;
  rating_health_wellness?: string | null;
  rating_history?: string | null;
  rating_literature?: string | null;
  rating_music_film?: string | null;
  rating_philosophy?: string | null;
  rating_politics_events?: string | null;
  rating_sports_recreation?: string | null;
  rating_spirituality_religion?: string | null;
  rating_tech_science?: string | null;
  referred_by_person_id?: string | null;
  referred_by_text?: string | null;
  risk_tolerance?: string | null;
  score_conversation_focus?: number | null;
  score_energy?: number | null;
  score_experience?: number | null;
  score_extraversion?: number | null;
  score_intention_focus?: number | null;
  score_overall?: number | null;
  score_slope?: number | null;
  score_transactional?: number | null;
  score_vulnerability?: number | null;
  score_work_life?: number | null;
  secondary_email?: string | null;
  sexual_orientation?: string | null;
  status?: string | null;
  stripe_customer_id?: string | null;
  test_account?: boolean | null;
  three_words?: string | null;
  time_in_city?: string | null;
  updated_at?: any | null;
  user_id?: string | null;
  value_expectation?: string | null;
  work_description?: string | null;
  referred_by_person?: { id: string; first_name?: string | null; last_name?: string | null } | null;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { getRoles: Array<{ id: number; name: string }> };

export type GetStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatsQuery = {
  getStats: { interviewStats: { interviewWins: number; interviewLoss: number } };
};

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type CreateUserMutation = {
  createUser: {
    id: string;
    auth_id?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { deleteUser: boolean };

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  getLoggedInUser: {
    auth_id?: string | null;
    email: string;
    name: string;
    id: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  getUsers: Array<{
    id: string;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    id: string;
    auth_id?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    accomplishments
    access_requirements
    age_matching_preference
    airtable_id
    application_age_range
    application_dinner_guest
    archetype
    avatar_path
    city
    connection_expectation
    credit_balance
    created_at
    date_of_birth
    dietary_preferences
    dietary_preferences_note
    dinner_persona
    ethnicity
    extraversion
    family_status
    first_name
    gender
    has_children
    industry
    interests
    is_ambassador
    last_name
    membership_charge_period
    membership_started_at
    membership_status
    nomination_code
    phone_number
    primary_email
    rating_arts_culture
    rating_business
    rating_health_wellness
    rating_history
    rating_literature
    rating_music_film
    rating_philosophy
    rating_politics_events
    rating_sports_recreation
    rating_spirituality_religion
    rating_tech_science
    referred_by_person_id
    referred_by_text
    risk_tolerance
    score_conversation_focus
    score_energy
    score_experience
    score_extraversion
    score_intention_focus
    score_overall
    score_slope
    score_transactional
    score_vulnerability
    score_work_life
    secondary_email
    sexual_orientation
    status
    stripe_customer_id
    test_account
    three_words
    time_in_city
    updated_at
    user_id
    value_expectation
    work_description
    referred_by_person {
      id
      first_name
      last_name
    }
  }
`;
export const GetInterviewsDocument = gql`
  query GetInterviews($filters: InterviewFilterInput!) {
    getInterviews(filters: $filters) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
      person {
        ...PersonFragment
      }
      interviewer {
        auth_id
        email
        name
        id
        roles {
          id
          name
        }
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetInterviewsQuery__
 *
 * To run a query within a React component, call `useGetInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInterviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables> &
    ({ variables: GetInterviewsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export type GetInterviewsQueryHookResult = ReturnType<typeof useGetInterviewsQuery>;
export type GetInterviewsLazyQueryHookResult = ReturnType<typeof useGetInterviewsLazyQuery>;
export type GetInterviewsSuspenseQueryHookResult = ReturnType<typeof useGetInterviewsSuspenseQuery>;
export type GetInterviewsQueryResult = Apollo.QueryResult<
  GetInterviewsQuery,
  GetInterviewsQueryVariables
>;
export const PatchInterviewDocument = gql`
  mutation PatchInterview($id: String!, $payload: PatchInterview!) {
    patchInterview(id: $id, payload: $payload) {
      id
      date
      deferred_to
      interviewer_id
      person_id
      follow_up_notes
      deferred_to
      status
      notes
      created_at
      updated_at
    }
  }
`;
export type PatchInterviewMutationFn = Apollo.MutationFunction<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;

/**
 * __usePatchInterviewMutation__
 *
 * To run a mutation, you first call `usePatchInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchInterviewMutation, { data, loading, error }] = usePatchInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchInterviewMutation, PatchInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchInterviewMutation, PatchInterviewMutationVariables>(
    PatchInterviewDocument,
    options,
  );
}
export type PatchInterviewMutationHookResult = ReturnType<typeof usePatchInterviewMutation>;
export type PatchInterviewMutationResult = Apollo.MutationResult<PatchInterviewMutation>;
export type PatchInterviewMutationOptions = Apollo.BaseMutationOptions<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;
export const CreateKanbanCardDocument = gql`
  mutation CreateKanbanCard($payload: CreateKanbanCardInput!) {
    createKanbanCards(payload: $payload) {
      id
    }
  }
`;
export type CreateKanbanCardMutationFn = Apollo.MutationFunction<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;

/**
 * __useCreateKanbanCardMutation__
 *
 * To run a mutation, you first call `useCreateKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKanbanCardMutation, { data, loading, error }] = useCreateKanbanCardMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKanbanCardMutation,
    CreateKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKanbanCardMutation, CreateKanbanCardMutationVariables>(
    CreateKanbanCardDocument,
    options,
  );
}
export type CreateKanbanCardMutationHookResult = ReturnType<typeof useCreateKanbanCardMutation>;
export type CreateKanbanCardMutationResult = Apollo.MutationResult<CreateKanbanCardMutation>;
export type CreateKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;
export const GetFollowupKanbanCardsDocument = gql`
  query GetFollowupKanbanCards($filters: FollowupKanbanCardInputs!) {
    getFollowupKanbanCards(filters: $filters) {
      id
      status
      created_at
      updated_at
      interview {
        id
        person {
          id
          first_name
          last_name
          city
          primary_email
        }
        interviewer {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetFollowupKanbanCardsQuery__
 *
 * To run a query within a React component, call `useGetFollowupKanbanCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowupKanbanCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowupKanbanCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetFollowupKanbanCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  > &
    ({ variables: GetFollowupKanbanCardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFollowupKanbanCardsQuery,
        GetFollowupKanbanCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export type GetFollowupKanbanCardsQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsQuery
>;
export type GetFollowupKanbanCardsLazyQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsLazyQuery
>;
export type GetFollowupKanbanCardsSuspenseQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsSuspenseQuery
>;
export type GetFollowupKanbanCardsQueryResult = Apollo.QueryResult<
  GetFollowupKanbanCardsQuery,
  GetFollowupKanbanCardsQueryVariables
>;
export const PatchFollowupKanbanCardDocument = gql`
  mutation PatchFollowupKanbanCard($id: String!, $payload: PatchFollowupKanbanCardInput!) {
    patchFollowupKanbanCards(id: $id, payload: $payload) {
      id
    }
  }
`;
export type PatchFollowupKanbanCardMutationFn = Apollo.MutationFunction<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;

/**
 * __usePatchFollowupKanbanCardMutation__
 *
 * To run a mutation, you first call `usePatchFollowupKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchFollowupKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchFollowupKanbanCardMutation, { data, loading, error }] = usePatchFollowupKanbanCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchFollowupKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >(PatchFollowupKanbanCardDocument, options);
}
export type PatchFollowupKanbanCardMutationHookResult = ReturnType<
  typeof usePatchFollowupKanbanCardMutation
>;
export type PatchFollowupKanbanCardMutationResult =
  Apollo.MutationResult<PatchFollowupKanbanCardMutation>;
export type PatchFollowupKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;
export const PatchPersonDocument = gql`
  mutation PatchPerson($id: String!, $payload: PatchPerson!) {
    patchPerson(id: $id, payload: $payload) {
      id
    }
  }
`;
export type PatchPersonMutationFn = Apollo.MutationFunction<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;

/**
 * __usePatchPersonMutation__
 *
 * To run a mutation, you first call `usePatchPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchPersonMutation, { data, loading, error }] = usePatchPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchPersonMutation, PatchPersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchPersonMutation, PatchPersonMutationVariables>(
    PatchPersonDocument,
    options,
  );
}
export type PatchPersonMutationHookResult = ReturnType<typeof usePatchPersonMutation>;
export type PatchPersonMutationResult = Apollo.MutationResult<PatchPersonMutation>;
export type PatchPersonMutationOptions = Apollo.BaseMutationOptions<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    getRoles {
      id
      name
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetStatsDocument = gql`
  query GetStats {
    getStats {
      interviewStats {
        interviewWins
        interviewLoss
      }
    }
  }
`;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsSuspenseQueryHookResult = ReturnType<typeof useGetStatsSuspenseQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      auth_id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetLoggedInUserDocument = gql`
  query GetLoggedInUser {
    getLoggedInUser {
      auth_id
      email
      name
      id
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<
  typeof useGetLoggedInUserSuspenseQuery
>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    getUsers {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      auth_id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
