import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { IKanbanCard, KanbanCard } from '@/components/kanban/KanbanCard';
import { IKanbanColumn, KanbanColumn } from '@/components/kanban/KanbanColumn';

type Props<T> = {
  data: IKanbanColumn<T>[];
  onItemColumnChange?: (itemId: string, status: string) => void;
  onItemClick?: (item: T) => void;
};

export const Kanban = <T,>({ data, onItemColumnChange, onItemClick }: Props<T>) => {
  const [columns, setColumns] = useState<IKanbanColumn<T>[]>(data);
  const [activeCard, setActiveCard] = useState<IKanbanCard<T>>();
  const [activeColumnId, setActiveColumnId] = useState<string>();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor),
  );

  const findColumn = (columnId: string | null, tempColumns: IKanbanColumn<T>[]) => {
    if (!columnId) {
      return null;
    }

    return tempColumns.find((c) => c.id === columnId) ?? null;
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    if (!over || !activeCard) return;
    /*
     * handleDragOver is triggered when a task is dragged over another card OR a column
     * */

    // Get the target column id
    const targetColumnId =
      over.data.current?.type === 'card' ? over.data.current?.sortable?.containerId : over.id;

    setActiveColumnId(targetColumnId);
  };

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { data } = active;
    setActiveCard(data.current?.details);
  }

  function handleDragEnd({ over, active }: DragEndEvent) {
    if (!over || !active || !activeCard) return;

    // Get the initial and target column id
    const initialColumnId =
      active.data.current?.type === 'card' ? active.data.current?.sortable?.containerId : active.id;
    const targetColumnId =
      over.data.current?.type === 'card' ? over.data.current?.sortable?.containerId : over.id;

    setActiveColumnId(targetColumnId);

    // Order the item list based on target item position
    setColumns((columns) => {
      const tempColumns = [...columns];
      const initialColumn = findColumn(initialColumnId, tempColumns);

      // If the item is drag around in the same container then just reorder the list
      if (initialColumnId === targetColumnId) {
        if (initialColumn) {
          const oldIndex = initialColumn?.cards.findIndex(
            (card) => active.id.toString() === card.id,
          );
          const newIndex = initialColumn?.cards.findIndex(
            (card) => over!.id.toString() === card.id,
          );

          initialColumn.cards = arrayMove(initialColumn.cards, oldIndex, newIndex);
        }
      } else {
        // If the item is drag into another different container
        // Remove item from its initial container
        const targetColumn = findColumn(targetColumnId, tempColumns);
        if (initialColumn && targetColumn) {
          const card = initialColumn.cards.find((card) => card.id === activeCard.id)!;
          if (!card) {
            return tempColumns;
          }

          initialColumn.cards = initialColumn.cards.filter(
            (card) => card.id !== active.id.toString(),
          );

          // Add item to it's target container
          const newIndex = targetColumn.cards.findIndex((card) => card.id === over.id.toString());
          targetColumn!.cards.splice(newIndex, 0, card);

          if (onItemColumnChange && activeCard) {
            onItemColumnChange(activeCard.id, targetColumnId);
          }
        }
      }

      return tempColumns;
    });

    setActiveCard(undefined);
    setActiveColumnId(undefined);
  }

  useEffect(() => {
    setColumns(data);
  }, [data]);

  return (
    <div className={'flex h-full flex-row overflow-x-auto rounded-lg bg-gray-200'}>
      <DndContext
        sensors={sensors}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        collisionDetection={rectIntersection}
      >
        {columns.map((column) => (
          <KanbanColumn
            active={column.id === activeColumnId}
            key={column.id}
            id={column.id}
            title={column.title}
            cards={column.cards}
            onItemClick={onItemClick}
          />
        ))}

        <DragOverlay adjustScale={false}>
          {/* Drag Overlay For Card */}
          {activeCard && <KanbanCard {...activeCard} />}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
