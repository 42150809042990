import { ReactElement, useEffect, useState } from 'react';

import { useAuth } from '@/hooks/useAuth';
import { InsufficientRolesPage } from '@/pages/InsufficientRolesPage';
import { RolesEnum } from '@/shared/enums';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';

type Props = {
  element: ReactElement;
  roles?: RolesEnum[]; // If no roles, only admin and owner have access
};

export const RoleRoute = ({ element, roles }: Props) => {
  const { user } = useAuth();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (user) {
      setHasAccess(hasRoleAccess(user!.roles, roles || []));
    }
  }, [user, roles]);

  return hasAccess ? element : <InsufficientRolesPage />;
};
