import { enqueueSnackbar } from 'notistack';

export const toTitleCase = (input: string): string => {
  return input
    .split(/[-\s]+/) // Split by space or hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  // Remove any non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, '');
  // Match the cleaned number into groups of digits
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // If the input matches the expected format (10 digits), format it
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return the original input if it doesn't match the expected format
  return phoneNumber;
};

export const copyToClipboard = async (textToCopy: string | number) => {
  await navigator.clipboard.writeText(String(textToCopy));
  enqueueSnackbar('Copied!');
};
