import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface Props {
  name: string;
  path?: string;
  icon?: any;
  show?: boolean;
}

export const NavigationLink = ({ name, icon, path, show }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Icon = icon;

  if (show === false) {
    return null;
  }

  return (
    <div
      onClick={path ? () => navigate(path) : undefined}
      className={twMerge(
        path === location.pathname
          ? 'bg-gray-700 text-white'
          : 'text-gray-500 hover:bg-gray-800 hover:text-white',
        'group flex cursor-pointer items-center justify-start gap-x-3 whitespace-nowrap rounded-md py-1 text-sm font-semibold leading-6',
      )}
    >
      <div className={'h-5 w-5 px-4'}>
        {Icon && <Icon className="box-content h-5 w-5 shrink-0" />}
      </div>
      {name}
    </div>
  );
};
