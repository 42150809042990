import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from '@/__generated__/graphql';

export const useUsers = () => {
  const { loading: isLoading, data: usersData, error, refetch: refetchUsers } = useGetUsersQuery();

  const [deleteUserMutation, { loading: deleteLoading }] = useDeleteUserMutation({
    onCompleted: () => refetchUsers(),
  });

  const [updateUserMutation, { loading: updateLoading }] = useUpdateUserMutation({
    onCompleted: () => refetchUsers(),
  });

  const [createUserMutation, { loading: postLoading }] = useCreateUserMutation({
    onCompleted: () => refetchUsers({}),
  });

  return {
    isLoading,
    users: usersData?.getUsers || [],
    error,
    deleteUserMutation,
    updateUserMutation,
    createUserMutation,
    mutationLoading: deleteLoading || updateLoading || postLoading,
  };
};
