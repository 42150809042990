import { groupBy } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import {
  FollowupKanbanCard,
  useGetFollowupKanbanCardsQuery,
  usePatchFollowupKanbanCardMutation,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import {
  IFilter,
  IFilterCondition,
  IFilterConditionOperator,
} from '@/components/FilterPanel/FilterPanelContent';
import { applyFilter } from '@/components/FilterPanel/filterPanelUtils/filterUtils';
import { Kanban } from '@/components/kanban/Kanban';
import { IKanbanColumn } from '@/components/kanban/KanbanColumn';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { useAuth } from '@/hooks/useAuth';
import { FollowUpDetails } from '@/pages/Interviews/InterviewFollowUpPage/components/FollowUpDetails';
import { FollowUpBoardStatusEnum } from '@/shared/enums';
import { isRoleAdmin } from '@/shared/utils/rolesUtils';

const STATUS = [
  FollowUpBoardStatusEnum.ReadyToCall,
  FollowUpBoardStatusEnum.FirstAttempt,
  FollowUpBoardStatusEnum.SecondAttempt,
  FollowUpBoardStatusEnum.ClosedWon,
  FollowUpBoardStatusEnum.ClosedLost,
];

export const InterviewFollowUpPage = () => {
  const { user } = useAuth();
  const isAdminUser = isRoleAdmin(user!.roles, 'interviewer');
  const followupsResult = useGetFollowupKanbanCardsQuery({
    variables: {
      filters: { interviewer_id: isAdminUser ? undefined : user?.id },
    },
  });
  const followUps = followupsResult.data?.getFollowupKanbanCards;
  const [patchFollowupKanbanCard] = usePatchFollowupKanbanCardMutation({
    onCompleted: () => followupsResult.refetch(),
  });
  const [activeCard, setActiveCard] = useState<FollowupKanbanCard>();
  const kanbanColumns: IKanbanColumn<FollowupKanbanCard>[] = STATUS.map((status) => ({
    id: status,
    title: status,
    cards: [],
  }));
  const [filters, setFilters] = useState<IFilterCondition[]>([]);
  const [kanbanBoard, setKanbanBoard] =
    useState<IKanbanColumn<FollowupKanbanCard>[]>(kanbanColumns);

  const formatCards = (cards: FollowupKanbanCard[]) => {
    return cards.map((card) => ({
      id: card.id,
      title: `${card.interview.person?.first_name} ${card.interview.person?.last_name} `,
      param1: {
        label: 'Interviewer',
        value: card.interview.interviewer?.name || '-',
      },
      param2: {
        label: 'City',
        value: card.interview.person.city || '-',
      },
      param3: {
        label: 'Email',
        value: card.interview.person.primary_email || '-',
      },
      original: card,
    }));
  };

  const handleCardStatusChange = async (cardId: string, status: string) => {
    try {
      await patchFollowupKanbanCard({ variables: { id: cardId, payload: { status } } });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Failed to update Follow Up', { variant: 'error' });
    }
  };

  const handleCardClick = (followup: FollowupKanbanCard) => {
    setActiveCard(followup);
  };

  const getFilters = useCallback((): IFilter[] => {
    if (followUps) {
      const cities = Array.from(
        new Set(
          followUps.filter((fu) => fu.interview.person.city).map((fu) => fu.interview.person.city!),
        ),
      ) as string[];

      const interviewers = Array.from(
        new Set(
          followUps
            .filter((fu) => fu.interview.interviewer.name)
            .map((fu) => fu.interview.interviewer.name!),
        ),
      ) as string[];

      return [
        { field: 'city', values: cities, type: 'select' },
        { field: 'interviewer', values: interviewers, type: 'select' },
      ];
    }
    return [];
  }, [followUps]);

  useEffect(() => {
    if (!followUps?.length) {
      return;
    }

    const completeFilters = filters.filter((filter) => filter.field && filter.value);
    const filteredFollowUps = completeFilters.length
      ? followUps.filter((item) => {
          return completeFilters.every((filter) => {
            let itemValue: string | null | undefined = null;

            if (filter.field === 'city') {
              itemValue = item?.interview?.person?.city;
            } else if (filter.field === 'interviewer') {
              itemValue = item.interview.interviewer.name;
            }

            if (itemValue == undefined) {
              return false;
            }

            return applyFilter(
              itemValue,
              filter.operator as IFilterConditionOperator,
              filter.value,
            );
          });
        })
      : followUps;
    const statusMap = groupBy(filteredFollowUps, 'status');
    const board = kanbanColumns.map((column) => ({
      ...column,
      cards: formatCards((statusMap[column.id] as any) || []),
    }));

    setKanbanBoard(board);
  }, [filters, followUps]);

  return (
    <BasicLayout
      title={'Follow Up'}
      className={'flex max-w-full flex-col px-0'}
      titleClassName={'px-4'}
    >
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <Kanban
        onItemColumnChange={handleCardStatusChange}
        onItemClick={handleCardClick}
        data={kanbanBoard}
      />
      <FollowUpDetails followUp={activeCard} onClose={() => setActiveCard(undefined)} />
    </BasicLayout>
  );
};
