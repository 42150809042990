import { RolesEnum } from '@/shared/enums';

export const pageRoles = {
  Dashboard: [],
  UserManagement: [RolesEnum.interviewerAdmin],
  InterviewQueue: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
  FinishedInterviews: [RolesEnum.interviewerAdmin, RolesEnum.interviewerApprover],
  InterviewFollowUp: [
    RolesEnum.interviewerAdmin,
    RolesEnum.interviewerApprover,
    RolesEnum.interviewer,
  ],
};
