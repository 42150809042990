import { TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';

export enum IFilterConditionOperator {
  EQUALS = 'is equal to',
  NOT_EQUALS = 'is not equal to',
  CONTAINS = 'contains',
}

export type IFilterCondition = {
  field: string;
  operator: string;
  value: string;
};

export type IFilter = {
  field: string;
  values: string[];
  type: 'string' | 'select';
};

type IFilterData = {
  fields: string[];
  values: { [key: string]: string[] };
};

type Props = {
  filterKeyValues: IFilter[];
  filters: IFilterCondition[];
  setFilters: (conditions: IFilterCondition[]) => void;
};

const operators = ['is equal to', 'is not equal to', 'contains'];

export const FilterPanelContent = ({ filters, filterKeyValues, setFilters }: Props) => {
  const [filterData, setFilterData] = useState<IFilterData>({ fields: [], values: {} });

  const addCondition = () => {
    setFilters([...filters, { field: '', operator: IFilterConditionOperator.EQUALS, value: '' }]);
  };

  const updateCondition = (index: number, key: keyof IFilterCondition, value: string) => {
    const updatedConditions = [...filters];
    updatedConditions[index][key] = value;
    setFilters(updatedConditions);
  };

  const removeCondition = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const tempFilters: IFilterData = {
      fields: [],
      values: {},
    };
    filterKeyValues?.forEach((filter) => {
      tempFilters.fields.push(filter.field);
      tempFilters.values[filter.field] = filter.values;
    });
    setFilterData(tempFilters);
  }, [filterKeyValues]);

  return (
    <div>
      {filters.map((condition, index) => (
        <div key={index} className="mb-3 flex items-center rounded-md bg-base-100 p-3">
          {/* Field Selector */}
          <Select
            value={condition.field}
            onChange={(e) => updateCondition(index, 'field', e.target.value)}
          >
            <option value="">Select Field</option>
            {filterData.fields.map((field) => (
              <option key={field} value={field}>
                {field}
              </option>
            ))}
          </Select>

          {/* Operator Selector */}
          <Select
            value={condition.operator}
            onChange={(e) => updateCondition(index, 'operator', e.target.value)}
          >
            {operators.map((operator) => (
              <option key={operator} value={operator}>
                {operator}
              </option>
            ))}
          </Select>

          {/* Value Selector */}
          {condition.operator === IFilterConditionOperator.CONTAINS ? (
            <Input
              inputSize={'md'}
              value={condition.value}
              onChange={(e) => updateCondition(index, 'value', e.target.value)}
            />
          ) : (
            <Select
              value={condition.value}
              onChange={(e) => updateCondition(index, 'value', e.target.value)}
              disabled={!condition.field}
            >
              <option value="">Select Value</option>
              {condition.field &&
                filterData.values?.[condition.field]?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
            </Select>
          )}

          {/* Remove Button */}
          <Button
            //size={'sm'}
            shape={'square'}
            color={'error'}
            className={'bg-error p-1 text-white'}
            variant={'ghost'}
            onClick={() => removeCondition(index)}
          >
            <TrashIcon className={'w-6'} />
          </Button>
        </div>
      ))}

      <div className={'flex items-center gap-2'}>
        {!filters.length ? (
          <p className={'min-w-[200px] p-2 text-gray-700'}>No filters applied</p>
        ) : null}
        <span className={'flex-1'} />
        <Button size={'sm'} onClick={addCondition}>
          Add Condition
        </Button>
      </div>
    </div>
  );
};
