import { IFilterConditionOperator } from '@/components/FilterPanel/FilterPanelContent';

export const applyFilter = (
  itemValue: string,
  operator: IFilterConditionOperator,
  conditionValue: string,
): boolean => {
  switch (operator) {
    case IFilterConditionOperator.EQUALS:
      return itemValue === conditionValue;
    case IFilterConditionOperator.NOT_EQUALS:
      return itemValue !== conditionValue;
    case IFilterConditionOperator.CONTAINS:
      return itemValue.toLowerCase().includes(conditionValue.toLowerCase());
    default:
      return false;
  }
};
