import dayjs from 'dayjs';
import { useState } from 'react';

import { Interview } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { ChangeInterviewerModal } from '@/pages/Interviews/InterviewsQueuePage/components/ChangeInterviewerModal';
import { getSignupUrl } from '@/shared/utils/newCustomerUtils';
import { copyToClipboard } from '@/shared/utils/utils';

interface Props {
  interview: Interview;
}

export const InterviewDetails = ({ interview }: Props) => {
  const [changeInterviewerModal, setChangeInterviewerModal] = useState(false);

  const details = [
    {
      title: 'Interview Date',
      value: interview.date
        ? `${new Date(interview.date).toLocaleDateString()} at ${new Date(interview.date).toLocaleTimeString()}`
        : '',
    },
    {
      title: 'Interviewer',
      value: (
        <div className={'flex gap-2'}>
          {interview.interviewer.name}
          <span className={'flex-1'} />
          <Button
            color={'secondary'}
            variant={'link'}
            size={'xs'}
            onClick={() => setChangeInterviewerModal(true)}
          >
            Change
          </Button>
        </div>
      ),
    },
    {
      title: 'Status',
      value: interview.status,
    },
    {
      title: 'Signup URL',
      value: (
        <div className={'flex w-full items-center'}>
          <p className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}>
            {getSignupUrl(interview.person.primary_email || '', interview.person.airtable_id || '')}
          </p>
          <Button
            variant={'link'}
            size={'xs'}
            color={'secondary'}
            onClick={() =>
              copyToClipboard(
                getSignupUrl(
                  interview.person.primary_email || '',
                  interview.person.airtable_id || '',
                ),
              )
            }
          >
            Copy
          </Button>
        </div>
      ),
    },
    {
      title: 'Reschedule URL',
      value: (
        <div className={'flex items-center'}>
          <p className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}>
            {getRescheduleUrl()}
          </p>
          <Button
            variant={'link'}
            size={'xs'}
            color={'secondary'}
            onClick={() => copyToClipboard(getRescheduleUrl())}
          >
            Copy
          </Button>
        </div>
      ),
    },
  ];

  function getRescheduleUrl() {
    return `https://calendly.com/d/ymg-fmt-x5s/base-membership-q-a?month=${dayjs().format('YYYY-MM')}`;
  }

  return (
    <>
      <DetailTableSection title={'Interview'} details={details} />
      <ChangeInterviewerModal
        isOpen={changeInterviewerModal}
        onClose={() => setChangeInterviewerModal(false)}
        selectedInterviewerId={interview.interviewer_id}
        interviewId={interview.id}
      />
    </>
  );
};
