type StatProps = {
  title: string;
  value: string | number;
  description?: string;
  shadow?: boolean; // Optional prop for styling
};

export const Stat = ({ title, value, description, shadow = true }: StatProps) => {
  return (
    <div className={`stats ${shadow ? 'shadow' : ''}`}>
      <div className="stat">
        <div className="stat-title">{title}</div>
        <div className="stat-value">{value}</div>
        <div className="stat-desc">{description}</div>
      </div>
    </div>
  );
};
