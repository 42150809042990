import { ReactNode, useEffect, useRef, useState } from 'react';

interface Props {
  trigger: ReactNode;
  triggerAction?: 'click' | 'hover';
  title?: string;
  content: string | ReactNode;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right';
}

const Popover = ({
  trigger,
  title,
  content,
  triggerAction = 'hover',
  vertical = 'top',
  horizontal = 'left',
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [translate, setTranslate] = useState(0);

  const togglePopover = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Check if the click happened outside of the popover
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  const getTriggerAction = () => {
    if (triggerAction === 'hover') {
      return {
        onMouseEnter: togglePopover,
        onMouseLeave: togglePopover,
      };
    }
    return {
      onClick: togglePopover,
    };
  };

  // Define position classes based on the direction
  const getPositionClasses = () => {
    let positionClasses = '';

    // Add vertical positioning classes
    positionClasses += vertical === 'top' ? 'bottom-full' : 'top-full';

    // Add horizontal positioning classes
    positionClasses += horizontal === 'right' ? 'left-full ' : 'right-full';

    return positionClasses.trim();
  };

  useEffect(() => {
    // Add event listener for clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && popoverRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (popoverRef?.current?.getBoundingClientRect) {
            const { left: parentLeft } = popoverRef.current.getBoundingClientRect();
            const { width } = entry.contentRect;
            // Check if the right edge of the content is beyond the viewport width
            if (parentLeft + width > window.innerWidth) {
              setTranslate(Math.round(30 + parentLeft + width - window.innerWidth));
            }
          }
        }
      });

      observer.observe(contentRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [contentRef.current, popoverRef.current, isVisible]);

  return (
    <div className={`relative inline-block`} ref={popoverRef}>
      <span className={'h-max w-max'} {...getTriggerAction()}>
        {trigger}
      </span>
      {isVisible && (
        <div
          style={{ transform: `translateX(-${translate}px)` }}
          ref={contentRef}
          className={`absolute z-10 w-max max-w-[300px] rounded-lg border border-gray-200 bg-white p-2 shadow-lg sm:max-w-screen-sm md:max-w-max ${getPositionClasses()}`}
        >
          {title && <h4 className="text-lg font-bold">Popover Title</h4>}
          {typeof content === 'string' ? <p className="text-sm">{content}</p> : content}
        </div>
      )}
    </div>
  );
};

export default Popover;
