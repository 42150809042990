import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

import { pageRoles } from '@/app/pageRoles';
import { NavigationLayout } from '@/components/layouts/NavigationLayout/NavigationLayout';
import { AuthRoute } from '@/components/routes/AuthRoute';
import { RoleRoute } from '@/components/routes/RoleRoute';
import { AuthPage } from '@/pages/AuthPage/AuthPage';
import { DashboardPage } from '@/pages/DashboardPage/DashboardPage';
import { ErrorBoundaryPage } from '@/pages/ErrorBoundaryPage';
import { InterviewFollowUpPage } from '@/pages/Interviews/InterviewFollowUpPage/InterviewFollowUpPage';
import { InterviewsQueuePage } from '@/pages/Interviews/InterviewsQueuePage/InterviewsQueuePage';
import { UsersPage } from '@/pages/UsersPage/UsersPage';

function App() {
  return (
    <ErrorBoundary
      fallbackRender={ErrorBoundaryPage}
      onError={(error, info) => console.error(error, info)}
      fallback={undefined}
    >
      <Routes>
        <Route path={'/login'} element={<AuthPage />} />
        <Route element={<AuthRoute element={<NavigationLayout />} />}>
          <Route path={'/'} element={<DashboardPage />} />
          <Route
            path={'/users'}
            element={<RoleRoute roles={pageRoles.UserManagement} element={<UsersPage />} />}
          />
          <Route
            path={'/interview-queue'}
            element={
              <RoleRoute element={<InterviewsQueuePage />} roles={pageRoles.InterviewQueue} />
            }
          />
          <Route
            path={'/finished-interviews'}
            element={
              <RoleRoute element={<InterviewsQueuePage />} roles={pageRoles.FinishedInterviews} />
            }
          />
          <Route
            path={'/interview-followups'}
            element={
              <RoleRoute element={<InterviewFollowUpPage />} roles={pageRoles.InterviewFollowUp} />
            }
          />
          {/*<Route*/}
          {/*  path={'/account-management'}*/}
          {/*  element={*/}
          {/*    <RoleRoute element={<AccountManagementPage />} roles={pageRoles.InterviewFollowUp} />*/}
          {/*  }*/}
          {/*/>*/}
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
