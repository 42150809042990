import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import {
  Interview,
  KanbanAssociationTypeEnum,
  KanbanBoardTypeEnum,
  useCreateKanbanCardMutation,
  usePatchInterviewMutation,
} from '@/__generated__/graphql';
import Popover from '@/components/Popover';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { InputLabel } from '@/components/basic/inputs/InputLabel';
import { StarInput } from '@/components/basic/inputs/StarInput';
import { QuillEditor } from '@/components/quillEditor/QuillEditor';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { useAuth } from '@/hooks/useAuth';
import { usePersons } from '@/hooks/usePersons';
import { ListFormatter } from '@/pages/Interviews/InterviewsQueuePage/components/ListFormatter';
import { FollowUpBoardStatusEnum, InterviewsStatusEnum, RolesEnum } from '@/shared/enums';
import { hasRoleAccess, isRoleAdmin } from '@/shared/utils/rolesUtils';

type Props = {
  interview: Interview;
};

type IDefaultForm = {
  interviewerNotes: string;
  slope?: number | null;
  experienceLevel?: number | null;
  transactionalRating?: number | null;
  vulnerabilityRating?: number | null;
  intentionFocusRating?: number | null;
  conversationFocus?: number | null;
  extraversionRating?: number | null;
  deferredTo?: string | undefined;
  energy?: number | null;
  workLife?: number | null;
  overall?: number | null;
};

const defaultForm: IDefaultForm = {
  deferredTo: '',
  interviewerNotes: '',
};

export const InterviewForm = ({ interview }: Props) => {
  const [form, setForm] = useState({ ...defaultForm });
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState<InterviewsStatusEnum>();
  const { user } = useAuth();
  const isApprover =
    isRoleAdmin(user?.roles || [], 'interviewer') ||
    hasRoleAccess(user?.roles || [], [RolesEnum.interviewerApprover]);
  const [patchInterviewMutation] = usePatchInterviewMutation();
  const { patchPersonMutation } = usePersons();
  const [createKanbanCard] = useCreateKanbanCardMutation();

  const starInputConfigurations = [
    {
      stars: 3,
      title: 'Slope',
      required: interview.status === InterviewsStatusEnum.InterviewFinished,
      name: 'slope',
      size: 'md',
      disabled: false,
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <>
              <p>Career Trajectory</p>
              <ListFormatter
                size={'xs'}
                list={['1: Steady', '2: Medium Growth', '3: Fast Growth']}
              />
            </>
          }
        />
      ),
    },
    {
      stars: 10,
      title: 'Experience Level',
      required: interview.status === InterviewsStatusEnum.InterviewFinished,
      name: 'experienceLevel',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              size={'xs'}
              list={[
                '1: Young professional. Starting off their career.',
                '2: Young professional. 2-4 years of experience.',
                '3: Young Professional, rising quickly with own business or joining a great venture.',
                '4: Great career in more traditional field.',
                '5: Leadership role or entrepreneur of a small business.',
                '6: Manager or senior at their position, or entreprenuer with chance for big success.',
                '7: Director Level/Successful Founder.',
                '8: Very accomplished professional.',
                '9: Exceptional skills - extremely achieved individual.',
                '10: Celebrity.',
              ]}
            />
          }
        />
      ),
    },
    {
      stars: 3,
      title: 'Transactional Rating',
      required: true,
      name: 'transactionalRating',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              size={'xs'}
              list={['3:	Highly transactional', '2:	Balanced', '1:	Seeking authenticity']}
            />
          }
        />
      ),
    },
    {
      stars: 3,
      title: 'Vulnerability Rating',
      required: true,
      name: 'vulnerabilityRating',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={[
                '1: Not open to sharing vulnerabilities',
                '2: Somewhat open',
                '3: Very open, shares personal doubts/pain',
              ]}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 3,
      title: 'Intention Focus Rating',
      required: true,
      name: 'intentionFocusRating',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={['1: Vague goals', '2: Somewhat clear', '3: Very clear, purpose-driven']}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 3,
      title: 'Conversation Focus',
      required: true,
      name: 'conversationFocus',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={[
                '1: Internal (emotions, people, past)',
                '2: Mix of internal and external',
                '3: External (ideas, topics, learnings)',
              ]}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 5,
      title: 'Extraversion Rating',
      required: true,
      name: 'extraversionRating',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={[
                '1: Very quiet, strong introvert',
                '2: Introvert, more of a listener',
                '3: Balanced, engages without dominating',
                '4: Extrovert, leads conversation',
                '5: Dominates conversation, potentially self-centered',
              ]}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 10,
      title: 'Energy',
      required: true,
      name: 'energy',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={[
                '1-3: Low energy, unengaging',
                '4-6: Moderate energy, somewhat engaging',
                '7-10: High energy, very engaging',
              ]}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 3,
      title: 'Work Life',
      required: true,
      name: 'workLife',
      labelAction: (
        <Popover
          trigger={<InformationCircleIcon className={'h-4 w-4'} />}
          content={
            <ListFormatter
              list={[
                '1: Primarily work-focused',
                '2: Mix of work and life topics',
                '3: Primarily life-focused',
              ]}
              size={'xs'}
            />
          }
        />
      ),
    },
    {
      stars: 5,
      title: 'Overall',
      required: true,
      name: 'overall',
    },
  ];

  const handleChange = (event) => {
    setIsDirty(true);
    setForm((preForm) => ({ ...preForm, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (interview.status === InterviewsStatusEnum.InterviewFinished) {
        setLoading(InterviewsStatusEnum.InvitedToMembership);
        await Promise.all([
          updateInterviewStatus(InterviewsStatusEnum.InvitedToMembership),
          createKanbanCard({
            variables: {
              payload: {
                board_type: KanbanBoardTypeEnum.FollowUpApplicants,
                status: FollowUpBoardStatusEnum.ReadyToCall,
                associations: [
                  { entity_id: interview.id, entity_type: KanbanAssociationTypeEnum.Interviews },
                ],
              },
            },
          }),
        ]);
      } else {
        setLoading(InterviewsStatusEnum.InterviewFinished);
        await updateInterviewStatus(InterviewsStatusEnum.InterviewFinished);
      }
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error' });
    } finally {
      setLoading(undefined);
    }
  };

  // Function to handle debounced form change
  const autoSaveForm = useCallback(
    debounce(async (newForm: IDefaultForm) => {
      if (!isDirty) {
        return;
      }
      const { interviewerNotes, ...other } = newForm;

      await Promise.all([
        patchInterviewMutation({
          variables: {
            id: interview.id,
            payload: { notes: interviewerNotes, deferred_to: other.deferredTo || null },
          },
        }),
        patchPersonMutation({
          variables: {
            id: interview.person_id,
            payload: {
              score_extraversion: other.extraversionRating,
              score_overall: other.overall,
              score_energy: other.energy,
              score_experience: other.experienceLevel,
              score_slope: other.slope,
              score_work_life: other.workLife,
              score_conversation_focus: other.conversationFocus,
              score_vulnerability: other.vulnerabilityRating,
              score_transactional: other.transactionalRating,
              score_intention_focus: other.intentionFocusRating,
            },
          },
        }),
      ]);
      setIsDirty(false);
    }, 300),
    [interview.id, interview.person_id, isDirty],
  );

  const updateInterviewStatus = async (status: InterviewsStatusEnum) => {
    try {
      setLoading(status);
      await patchInterviewMutation({
        variables: { id: interview.id, payload: { status } },
      });
      await patchPersonMutation({
        variables: { id: interview.person_id, payload: { status } },
      });
    } catch (error) {
      enqueueSnackbar(String(error), { variant: 'error' });
    } finally {
      setLoading(undefined);
    }
  };

  const renderButtons = () => {
    if (interview.status === InterviewsStatusEnum.InvitedToMembership) {
      return null;
    }

    if (interview.status === InterviewsStatusEnum.InterviewFinished && !isApprover) {
      return null;
    }

    const buttonsConfig =
      interview.status === InterviewsStatusEnum.InterviewFinished
        ? [
            {
              status: InterviewsStatusEnum.Rejected,
              label: 'Reject Applicant',
              onClick: () => updateInterviewStatus(InterviewsStatusEnum.Rejected),
              color: 'error',
            },
            {
              status: InterviewsStatusEnum.DeferredInterview,
              label: 'Defer Membership',
              onClick: () => updateInterviewStatus(InterviewsStatusEnum.DeferredInterview),
              color: 'info',
              disabled: !form.deferredTo,
            },
            {
              status: InterviewsStatusEnum.InvitedToMembership,
              label: 'Invite to Membership',
              onClick: () => {}, // handled by submit
              color: 'success',
              type: 'submit',
            },
          ]
        : [
            {
              status: InterviewsStatusEnum.NoCallNoAnswer,
              label: 'No Call/ No Answer',
              onClick: () => updateInterviewStatus(InterviewsStatusEnum.NoCallNoAnswer),
              color: 'error',
            },
            {
              status: InterviewsStatusEnum.NeedsRescheduling,
              label: 'Needs Rescheduling',
              onClick: () => updateInterviewStatus(InterviewsStatusEnum.NeedsRescheduling),
              color: 'info',
            },
            {
              status: InterviewsStatusEnum.InterviewFinished,
              label: 'Submit Interview',
              onClick: () => {}, // handled by submit
              color: 'success',
              type: 'submit',
            },
          ];

    const renderButton = (
      status: InterviewsStatusEnum,
      label: string,
      onClick: () => void,
      color: any,
      type?: any,
      disabled?: boolean,
    ) => (
      <Button
        disabled={!!loading || disabled}
        loading={loading === status}
        onClick={onClick}
        color={color}
        size={'sm'}
        type={type}
      >
        {label}
      </Button>
    );

    return (
      <>
        {renderButton(
          buttonsConfig[0].status,
          buttonsConfig[0].label,
          buttonsConfig[0].onClick,
          buttonsConfig[0].color,
          buttonsConfig[0].type,
          !!buttonsConfig[0].disabled,
        )}
        {renderButton(
          buttonsConfig[1].status,
          buttonsConfig[1].label,
          buttonsConfig[1].onClick,
          buttonsConfig[1].color,
          buttonsConfig[1].type,
          !!buttonsConfig[1].disabled,
        )}
        <span className={'flex-1'} />
        {renderButton(
          buttonsConfig[2].status,
          buttonsConfig[2].label,
          buttonsConfig[2].onClick,
          buttonsConfig[2].color,
          buttonsConfig[2].type,
          !!buttonsConfig[2].disabled,
        )}
      </>
    );
  };

  useEffect(() => {
    setIsDirty(false);
    setForm({
      interviewerNotes: interview.notes || defaultForm.interviewerNotes,
      slope: interview.person.score_slope,
      experienceLevel: interview.person.score_experience,
      transactionalRating: interview.person.score_transactional,
      vulnerabilityRating: interview.person.score_vulnerability,
      intentionFocusRating: interview.person.score_intention_focus,
      conversationFocus: interview.person.score_conversation_focus,
      extraversionRating: interview.person.score_extraversion,
      energy: interview.person.score_energy,
      workLife: interview.person.score_work_life,
      overall: interview.person.score_overall,
      deferredTo: interview.deferred_to || '',
    });
  }, [interview.id]);

  useEffect(() => {
    if (isDirty) {
      autoSaveForm(form);
    }

    // Cleanup: cancel the debounce on unmount
    return () => {
      autoSaveForm.cancel();
    };
  }, [form, autoSaveForm]);

  return (
    <SectionWithHeader title={'Interview Form'}>
      {form && (
        <form onSubmit={handleSubmit} className={'flex flex-col gap-4 p-2'}>
          <div className={'grid grid-cols-1 gap-2 xl:grid-cols-2'}>
            {starInputConfigurations.map((config) => {
              return (
                <StarInput
                  key={config.name}
                  label={config.title}
                  value={form[config.name]}
                  name={config.name}
                  maxStars={config.stars}
                  required={config.required}
                  onChange={handleChange}
                  labelAction={config?.labelAction}
                />
              );
            })}
          </div>

          {isApprover && interview.status === InterviewsStatusEnum.InterviewFinished ? (
            <Input
              label={'Defer To Date'}
              type={'date'}
              fullWidth={false}
              name={'deferredTo'}
              onChange={handleChange}
              value={form.deferredTo}
            />
          ) : null}

          <div className={'relative'}>
            <InputLabel label={'Interview Notes'} required />
            <QuillEditor
              required={true}
              value={form.interviewerNotes}
              onChange={(value) => handleChange({ target: { value, name: 'interviewerNotes' } })}
            />
          </div>
          <div className={'flex flex-wrap gap-4 lg:gap-1'}>{renderButtons()}</div>
        </form>
      )}
    </SectionWithHeader>
  );
};
