export enum RolesEnum {
  owner = 'owner',
  admin = 'admin',
  interviewerAdmin = 'interviewer-admin',
  interviewerApprover = 'interviewer-approver',
  interviewer = 'interviewer',
}

export enum PersonsStatusEnum {
  Accepted = 'Accepted',
  AmbassadorOnly = 'Ambassador Only',
  AttendedGuestEvent = 'Attended guest event',
  Cancelled = 'Cancelled',
  DeclinedMembership = 'Declined membership',
  DeferredInterview = 'Deferred interview',
  DeferredMembership = 'Deferred membership',
  DinnerGuest = 'Dinner guest',
  Duplicate = 'Duplicate',
  EmailBounced = 'Email bounced',
  Excluded = 'Excluded',
  InterviewHold = 'INTERVIEW HOLD',
  IgnoredMembership = 'Ignored membership',
  InterviewFinished = 'Interview finished',
  InterviewLaggard = 'Interview laggard',
  InterviewLaggardEnded = 'Interview laggard ended',
  InterviewNotScheduled = 'Interview not scheduled',
  InterviewScheduled = 'Interview scheduled',
  InvitedToInterview = 'Invited to interview',
  InvitedToMembership = 'Invited to membership',
  InvitesToMembership = 'Invites to membership',
  MembershipLaggard = 'Membership laggard',
  MembershipLaggardEnded = 'Membership laggard ended',
  NeedsRescheduling = 'Needs Rescheduling',
  NegotiatingMembership = 'Negotiating membership',
  NoCallNoAnswer = 'No call / no answer',
  Rejected = 'Rejected',
  Waiting = 'Waiting',
  WantsOnboardingDinner = 'Wants Onboarding Dinner',
  Paused = 'paused',
}

export enum InterviewsStatusEnum {
  Accepted = 'Accepted',
  AmbassadorOnly = 'Ambassador Only',
  AttendedGuestEvent = 'Attended guest event',
  Cancelled = 'Cancelled',
  CancelledByApplicant = 'Cancelled by applicant',
  DeclinedMembership = 'Declined membership', // *
  DeferredInterview = 'Deferred interview', // *
  DeferredMembership = 'Deferred membership',
  DinnerGuest = 'Dinner guest',
  Duplicate = 'Duplicate',
  EmailBounced = 'Email bounced',
  Excluded = 'Excluded',
  InterviewHold = 'INTERVIEW HOLD',
  IgnoredMembership = 'Ignored membership',
  InterviewFinished = 'Interview finished', // *
  InterviewLaggard = 'Interview laggard',
  InterviewLaggardEnded = 'Interview laggard ended',
  InterviewNotScheduled = 'Interview not scheduled',
  InterviewScheduled = 'Interview scheduled', // *
  InvitedToInterview = 'Invited to interview',
  InvitedToMembership = 'Invited to membership',
  InvitesToMembership = 'Invites to membership',
  MembershipLaggard = 'Membership laggard',
  MembershipLaggardEnded = 'Membership laggard ended',
  NeedsRescheduling = 'Needs Rescheduling', // *
  NegotiatingMembership = 'Negotiating membership',
  NoCallNoAnswer = 'No call / no answer', // *
  Rejected = 'Rejected',
  Waiting = 'Waiting',
  WantsOnboardingDinner = 'Wants Onboarding Dinner',
  Paused = 'paused',
}

export enum FollowUpBoardStatusEnum {
  ReadyToCall = 'Ready to Call',
  FirstAttempt = 'First Attempt',
  SecondAttempt = 'Second Attempt',
  ClosedWon = 'Closed Won',
  ClosedLost = 'Closed Lost',
}
