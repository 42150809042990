import { FC, ReactNode, createContext, useContext, useState } from 'react';

type SideDrawerContextType = {
  isOpen: boolean;
  toggleDrawer: (override?: boolean) => void;
};

// Create the context with default values
const NavigationLayoutContext = createContext<SideDrawerContextType | undefined>(undefined);

export const NavigationLayoutProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (override?: boolean) => setIsOpen(override ?? !isOpen);

  return (
    <NavigationLayoutContext.Provider value={{ isOpen, toggleDrawer }}>
      {children}
    </NavigationLayoutContext.Provider>
  );
};

// Custom hook for accessing the context
export const useNavigationLayoutContext = (): SideDrawerContextType => {
  const context = useContext(NavigationLayoutContext);
  if (!context) {
    throw new Error('useSideDrawer must be used within a SideDrawerProvider');
  }
  return context;
};
