import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

import Button from '@/components/basic/buttons/Button';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
};

export const RightSideDrawerBase = ({ children, isOpen, onClose, title }: Props) => {
  return (
    <>
      <div
        className={`fixed right-0 top-0 z-20 h-full w-screen transform bg-white p-4 shadow-lg transition-transform md:w-1/2 2xl:w-1/3 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className={'flex items-center justify-between gap-8'}>
          <h6 className={'text-xl font-semibold lg:text-3xl'}>{title}</h6>
          <Button
            size={'xs'}
            shape={'circle'}
            color={'ghost'}
            variant={'ghost'}
            className=""
            onClick={onClose}
          >
            <XMarkIcon />
          </Button>
        </div>
        {children}
      </div>
      {/*Backdrop*/}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
          isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
        onClick={onClose}
      />
    </>
  );
};
