import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { useAuth } from '@/hooks/useAuth';
import { InterviewStatsSection } from '@/pages/DashboardPage/SectionStats/Interviews/InterviewStatsSection';

export const DashboardPage = () => {
  const { user } = useAuth();
  return (
    <BasicLayout title={'Dashboard'}>
      {!user?.roles?.length ? (
        <p className={'p-4 text-xl italic'}>You do not have any roles.</p>
      ) : null}

      <InterviewStatsSection />
    </BasicLayout>
  );
};
