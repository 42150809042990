import { Role } from '@/__generated__/graphql';
import { RolesEnum } from '@/shared/enums';

/**
 * Returns a list of accessible roles for a user based on their current roles.
 *
 * @param {Role[]} allRoles - The complete list of possible roles in the system.
 * @param {Role[]} userRoles - The list of roles assigned to the user.
 * @return {Role[]} A list of roles that the user has access to.
 */
export function getAccessibleRoles(allRoles: Role[], userRoles: Role[]): Role[] {
  // If the user has 'owner' role, return all roles
  if (userRoles.some((role) => role.name === RolesEnum.owner)) {
    return allRoles;
  }

  // If the user has 'admin' role, return all roles except owner
  if (userRoles.some((role) => role.name === RolesEnum.admin)) {
    return allRoles.filter((role) => role.name !== RolesEnum.owner);
  }

  // If the user has no '-admin' role, return empty roles
  if (!userRoles.some((role) => role.name.endsWith('-admin'))) {
    return [];
  }

  // Set to store accessible role names for uniqueness
  const accessibleRoles = new Set<Role>();

  // Process each user role
  userRoles.forEach((userRole) => {
    // If user role has '-admin' suffix
    if (userRole.name.endsWith('-admin')) {
      const baseRole = userRole.name.split('-')[0];

      // Find all roles with the same base
      allRoles.forEach((role) => {
        if (role.name.startsWith(baseRole)) {
          accessibleRoles.add(role);
        }
      });
    }
  });

  return Array.from(accessibleRoles);
}

/**
 *
 */
export const isRoleAdmin = (userRoles: Role[], baseRole: string) => {
  const userRolesSet = new Set(userRoles.map((role) => role.name));
  return (
    userRolesSet.has(`admin`) || userRolesSet.has(`owner`) || userRolesSet.has(`${baseRole}-admin`)
  );
};

export const hasBaseRoleAccess = (userRoles: Role[], baseRole: string) => {
  if (
    userRoles.some(
      (role) => role.name.includes(RolesEnum.admin) || role.name.includes(RolesEnum.owner),
    )
  ) {
    return true;
  }

  return userRoles.some((role) => role.name.includes(baseRole));
};

export const hasRoleAccess = (userRoles: Role[], roles: RolesEnum[]) => {
  if (!userRoles.length) {
    return false;
  }

  // By default, owner and admins will have full access to every page
  const roleSet = new Set(
    [...(roles ?? []), RolesEnum.admin, RolesEnum.owner].map((role) => role.toString()),
  );

  for (const role of userRoles) {
    if (roleSet.has(role.name)) {
      return true;
    }
  }

  return false;
};
