import { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import LOGO from '@/assets/images/logo.svg';
import { useAuth } from '@/hooks/useAuth';

export const AuthPage = () => {
  const navigate = useNavigate();
  const { user, signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      if (error) {
        setError('');
      }
      setLoading(true);
      await signIn();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <div className={'flex h-full w-full flex-col items-center justify-center bg-gray-800'}>
      <div
        className={
          'flex h-fit w-full max-w-[600px] -translate-y-[20%] flex-col items-center justify-center'
        }
      >
        <h4 className={'font-["Forum"] text-lg text-[#FFE2C0]'}>Great Minds Work Here</h4>
        <img
          src={LOGO}
          alt={'logo'}
          className={'-my-[20px] h-full w-full max-w-[30%] object-contain'}
        />
        <h1 className={'font-["Forum"] text-6xl text-[#FFE2C0]'}>Login</h1>
        <button
          className={'btn border-none bg-[#EA4335] text-white hover:bg-[#EA4335]'}
          onClick={handleLogin}
        >
          <FaGoogle />
          Google
          {loading && <span className={'loading'} />}
        </button>

        <p className={'mt-8 text-sm text-error'}>{error}</p>
      </div>
    </div>
  );
};
