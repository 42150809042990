import { Bars3Icon } from '@heroicons/react/24/solid';

import Button from '@/components/basic/buttons/Button';
import { useNavigationLayoutContext } from '@/components/layouts/NavigationLayout/context/navigationLayoutContext';

export const NavBar = () => {
  const { toggleDrawer } = useNavigationLayoutContext();
  return (
    <div className={'flex min-h-10 w-full items-center justify-start bg-gray-800 md:hidden'}>
      <Button
        onClick={() => toggleDrawer()}
        shape={'circle'}
        color={'ghost'}
        size={'sm'}
        className={'mx-3 text-info'}
      >
        <Bars3Icon />
      </Button>
    </div>
  );
};
