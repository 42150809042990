import { Interview } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { formatPhoneNumber } from '@/shared/utils/utils';

interface Props {
  interview: Interview;
}

export const PersonDetails = ({ interview }: Props) => {
  const person = interview.person;

  const details = [
    { title: 'Full Name', value: `${person.first_name} ${person.last_name}` },
    { title: 'City', value: person.city },
    {
      title: 'Age Range',
      value: person.application_age_range,
    },
    {
      title: 'Email',
      value: person.primary_email,
    },
    {
      title: 'Phone Number',
      value: formatPhoneNumber(person.phone_number || ''),
    },
    {
      title: 'Referred By',
      value: person.referred_by_person?.first_name
        ? `${person.referred_by_person?.first_name} ${person.referred_by_person?.last_name}`
        : '',
    },
  ];

  return <DetailTableSection title={'Person'} details={details} />;
};
