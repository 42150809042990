import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetLoggedInUserQuery } from '@/__generated__/graphql';
import { apolloClient } from '@/modules/apolloClient';
import { supabase } from '@/modules/supabase';

export const useAuth = () => {
  const navigate = useNavigate();
  // Initially set to true so it attempts to fetch user
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const {
    error,
    data: userPayload,
    loading: isLoading,
    called,
  } = useGetLoggedInUserQuery({ skip: !loggedIn, pollInterval: 1000 * 60 });

  const signIn = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.origin,
      },
    });
    if (error) {
      throw error;
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      throw error;
    }
    await apolloClient.clearStore();
    navigate('/login');
  };

  useEffect(() => {
    const auth = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN') {
        setLoggedIn(true);
      } else if (event === 'SIGNED_OUT') {
        setLoggedIn(false);
      }
    });

    return () => {
      auth?.data?.subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (error) {
      setLoggedIn(false);
    }
  }, [error]);

  return {
    signIn,
    signOut,
    user: userPayload?.getLoggedInUser,
    isLoading,
    error,
    isFetched: called,
  };
};
