import { ArrowDownIcon } from '@heroicons/react/24/solid';
import React from 'react';
import DataTable, { TableProps, TableStyles } from 'react-data-table-component';

const tableCustomStyles: TableStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: 'Forum',
    },
  },
};

const sortIcon = <ArrowDownIcon />;
const selectProps = { indeterminate: (isIndeterminate: boolean) => isIndeterminate };

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
  return (
    <DataTable
      customStyles={tableCustomStyles}
      highlightOnHover={true}
      className={'overflow-auto rounded-md border'}
      pagination
      selectableRowsComponent={<input type="checkbox" />}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      fixedHeader={props.fixedHeader ?? true}
      dense={true}
      {...props}
    />
  );
}

export default DataTableBase;
