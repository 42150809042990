import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { CSSProperties, useEffect, useState } from 'react';

import { IKanbanCard, KanbanCard } from '@/components/kanban/KanbanCard';

export type IKanbanColumn<T> = {
  id: string;
  title: string;
  cards: IKanbanCard<T>[];
  active?: boolean;
  onItemClick?: (item: T) => void;
};

export const KanbanColumn = <T,>({ id, title, cards, active, onItemClick }: IKanbanColumn<T>) => {
  const { setNodeRef } = useDroppable({ id: id, data: { type: 'column' } });
  const [style, setStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (active) {
      setStyle({ border: '2px', borderColor: 'orange', borderStyle: 'dashed' });
    } else {
      setStyle({});
    }
  }, [active]);

  return (
    <div
      ref={setNodeRef}
      className={`flex w-full min-w-[250px] max-w-[350px] flex-col border border-gray-300 bg-white lg:min-w-[300px]`}
    >
      <h5
        className={
          'flex min-h-12 items-center justify-center overflow-y-auto text-lg font-semibold tracking-wide underline'
        }
      >
        {title} ( {cards.length} )
      </h5>
      <SortableContext id={id} items={cards.map((card) => card.id)}>
        <div className={`w-full flex-1 overflow-y-auto border-t`} style={style}>
          {cards.map((card) => (
            <KanbanCard onItemClick={onItemClick} key={card.id} {...card} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
};
