import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  error: any;
  resetErrorBoundary: any;
};

export const ErrorBoundaryPage = ({ error, resetErrorBoundary }: Props) => {
  const navigate = useNavigate();

  const handleNav = () => {
    resetErrorBoundary();
    navigate('/', {});
  };

  useEffect(() => {
    // TODO - report error
    console.error(error);
  }, []);

  return (
    <div className={'flex h-full w-full flex-col items-center justify-center'}>
      <h1 className={'translate-y-[-50%] text-center text-xl text-gray-800 sm:text-3xl'}>
        Mission Control, we have a problem.
        <br />
        <br />
        {/*<SentimentVeryDissatisfiedOutlined fontSize={'inherit'} className={'text-[10rem]'} />*/}
        <br />
        <br />
        If the issue continues, please contact us.
      </h1>

      <button onClick={handleNav}>To Safety</button>
    </div>
  );
};
