import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string | ReactNode;
  className?: HTMLDivElement['className'];
  titleClassName?: HTMLHeadElement['className'];
  children?: ReactNode;
}

export const BasicLayout = ({ className = '', title, titleClassName, children }: Props) => {
  return (
    <div className={twMerge('h-full w-full overflow-auto p-4', className)}>
      <h3 className={twMerge('font-["Forum"] text-2xl md:text-5xl', titleClassName)}>{title}</h3>
      {children}
    </div>
  );
};
