import { useEffect } from 'react';

import { useGetStatsQuery } from '@/__generated__/graphql';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { Stat } from '@/components/stats/Stat';
import { useAuth } from '@/hooks/useAuth';
import { hasBaseRoleAccess } from '@/shared/utils/rolesUtils';

export const InterviewStatsSection = () => {
  const { user } = useAuth();
  const hasInterviewerRole = hasBaseRoleAccess(user!.roles, 'interviewer');
  const { data, refetch } = useGetStatsQuery();
  const stats = data?.getStats;

  if (!hasInterviewerRole) {
    return null;
  }

  useEffect(() => {
    refetch();
  }, [user, hasInterviewerRole]);

  return (
    <SectionWithHeader title={'Interview Stats'} className={'w-max'}>
      <div className={'flex gap-2 p-2'}>
        <Stat title={'Total Closed Wins'} value={stats?.interviewStats?.interviewWins ?? '-'} />
        <Stat title={'Total Closed Losses'} value={stats?.interviewStats?.interviewLoss ?? '-'} />
      </div>
    </SectionWithHeader>
  );
};
