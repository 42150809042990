import { useGetRolesQuery } from '@/__generated__/graphql';

export const useRoles = () => {
  const { loading: isLoading, data, error } = useGetRolesQuery({});

  return {
    isLoading,
    roles: data?.getRoles || [],
    error,
  };
};
