import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { FollowupKanbanCard, usePatchInterviewMutation } from '@/__generated__/graphql';
import { RightSideDrawerBase } from '@/components/RightSideDrawerBase';
import Button from '@/components/basic/buttons/Button';
import { QuillEditor } from '@/components/quillEditor/QuillEditor';
import { getSignupUrl } from '@/shared/utils/newCustomerUtils';
import { copyToClipboard, formatPhoneNumber } from '@/shared/utils/utils';

type Props = {
  followUp?: FollowupKanbanCard;
  onClose: () => void;
};

export const FollowUpDetails = ({ followUp, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [followUpNotes, setFollowUpNotes] = useState('');
  const [patchInterviewMutation] = usePatchInterviewMutation();

  const details = [
    {
      label: 'Phone',
      value: formatPhoneNumber(followUp?.interview?.person?.phone_number || ''),
    },
    {
      label: 'Email',
      value: followUp?.interview?.person?.primary_email,
    },
    {
      label: 'Follow Up Status',
      value: followUp?.status,
    },
    {
      label: 'Person Status',
      value: followUp?.interview?.person.status,
    },
    {
      label: 'Interviewer',
      value: followUp?.interview?.interviewer?.name,
    },
    {
      label: 'Interview Date',
      value: followUp?.interview?.date ? new Date(followUp?.interview?.date).toDateString() : '',
    },
    {
      label: 'Signup URL (Click to Copy)',
      value: (
        <Button
          variant={'link'}
          size={'xs'}
          color={'secondary'}
          className={'text-left'}
          onClick={() =>
            copyToClipboard(
              getSignupUrl(
                followUp?.interview?.person.primary_email || '',
                followUp?.interview?.person.airtable_id || '',
              ),
            )
          }
        >
          {getSignupUrl(
            followUp?.interview?.person.primary_email || '',
            followUp?.interview?.person.airtable_id || '',
          )}
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      onClose();
    }, 500);
  };

  // Function to handle debounced form change
  const autoSaveForm = useCallback(
    debounce(async (notes: string) => {
      if (notes === followUp?.interview.follow_up_notes || !followUp?.interview?.interviewer_id) {
        return;
      }

      await patchInterviewMutation({
        variables: {
          id: followUp.interview.id,
          payload: { follow_up_notes: followUpNotes },
        },
      });
    }, 300),
    [followUp?.id, followUpNotes],
  );

  useEffect(() => {
    if (followUp) {
      setIsOpen(true);
      setFollowUpNotes(followUp.interview.follow_up_notes || '');
    } else {
      setIsOpen(false);
    }
  }, [followUp]);

  useEffect(() => {
    autoSaveForm(followUpNotes);

    return () => {
      autoSaveForm.cancel();
    };
  }, [followUpNotes]);

  return (
    <RightSideDrawerBase
      isOpen={isOpen}
      onClose={handleClose}
      title={`${followUp?.interview?.person?.first_name} ${followUp?.interview?.person?.last_name}`}
    >
      <div className={'mt-4 border-t pt-4'}>
        <div className={'mb-10 grid w-full grid-cols-2 gap-10'}>
          {details.map((detail) => (
            <LabelValue key={detail.label} label={detail.label} value={detail.value} />
          ))}
        </div>

        <LabelValue
          label={'Interviewer Notes'}
          value={
            <div
              className={'text-xs'}
              dangerouslySetInnerHTML={{ __html: followUp?.interview?.notes || 'No Notes' }}
            />
          }
        />
      </div>
      <LabelValue
        className={'mt-10'}
        label={'Follow Up Notes'}
        value={
          <QuillEditor className={'w-full'} value={followUpNotes} onChange={setFollowUpNotes} />
        }
      />
    </RightSideDrawerBase>
  );
};

const LabelValue = ({
  label,
  value,
  className,
}: {
  label: string;
  className?: HTMLDivElement['className'];
  value: any;
}) => {
  return (
    <div className={twMerge(`flex w-full flex-1 flex-col items-start justify-start`, className)}>
      <h6 className={'text-lg font-semibold underline'}>{label}</h6>
      {typeof value === 'object' ? value : <p className={'text-lg'}>{value}</p>}
    </div>
  );
};
