import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Interview } from '@/__generated__/graphql';
import Checkbox from '@/components/basic/inputs/Checkbox';
import { Queue } from '@/components/queue/Queue';
import { useAuth } from '@/hooks/useAuth';
import { useMobileDetection } from '@/hooks/useMobileDetection';
import { InterviewPageContent } from '@/pages/Interviews/InterviewsQueuePage/components/InterviewPageContent';
import { INTERVIEW_QUEUE_STATUS } from '@/pages/Interviews/shared/constants';
import { useInterviews } from '@/pages/Interviews/shared/hooks/useInterviews';
import { InterviewsStatusEnum } from '@/shared/enums';
import { isRoleAdmin } from '@/shared/utils/rolesUtils';

export const InterviewsQueuePage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { user } = useAuth();
  const navigate = useNavigate();
  const interviewUrlId = query.get('i');
  const [selectedInterview, setSelectedInterview] = useState<Interview>();
  const isAdmin = isRoleAdmin(user?.roles || [], 'interviewer');
  const [filters, setFilters] = useState({ allInterviews: true });
  const [isQueueDropdownOpen, setQueueDropdownOpen] = useState(false);
  const { isMobile } = useMobileDetection();
  const isFinishedInterviewsPage = location.pathname.includes('finished-interviews');
  const { interviews, refetchInterviews } = useInterviews({
    statuses: isFinishedInterviewsPage
      ? [InterviewsStatusEnum.InterviewFinished]
      : INTERVIEW_QUEUE_STATUS,
    startDate: isFinishedInterviewsPage
      ? undefined
      : dayjs().subtract(14, 'day').startOf('day').toISOString(),
    interviewerId: isAdmin ? undefined : user?.id,
  });

  const handleItemSelect = (item?: Interview) => {
    if (!item) {
      return;
    }

    const newQuery = new URLSearchParams(query.toString());
    newQuery.set('i', item.id);
    navigate(
      {
        search: newQuery.toString(),
      },
      { replace: true },
    );
  };

  const getItems = useCallback(() => {
    let filteredInterviews = filters.allInterviews
      ? interviews
      : interviews.filter((interview) => interview.interviewer_id === user?.id);

    if (isFinishedInterviewsPage) {
      filteredInterviews = filteredInterviews.filter(
        (interview) => interview.status === InterviewsStatusEnum.InterviewFinished,
      );
    }

    return filteredInterviews.map((interview) => ({
      ...interview,
      personName: `${interview.person.first_name} ${interview.person.last_name}`,
      interviewerName: 'i: ' + interview.interviewer.name,
      city: interview.person.city,
    }));
  }, [interviews, filters]);

  const getQueueConfiguration = () => {
    if (isFinishedInterviewsPage) {
      return {
        groupByParameter: 'city' as keyof Interview,
        filterBar: null,
        categoryConfigurations: {},
      };
    } else {
      return {
        groupByParameter: 'status' as keyof Interview,
        filterBar: isAdmin ? (
          <div className={'flex items-center gap-1 p-1'}>
            <span className={'flex-1'} />
            <Checkbox
              inputSize={'xs'}
              id={'allInterviews'}
              color={'secondary'}
              fullWidth={false}
              checked={filters.allInterviews}
              onChange={(e) => setFilters({ ...filters, allInterviews: e.target.checked })}
            />
            <label htmlFor={'allInterviews'} className={'text-sm'}>
              All Interviews
            </label>
          </div>
        ) : null,
        categoryConfigurations: {
          [InterviewsStatusEnum.InterviewScheduled]: {
            color: 'green',
          },
          [InterviewsStatusEnum.NeedsRescheduling]: {
            color: 'yellow',
          },
          [InterviewsStatusEnum.NoCallNoAnswer]: {
            color: 'red',
          },
          [InterviewsStatusEnum.InterviewFinished]: {
            color: 'black',
          },
        },
        hideMissingCategories: true,
      };
    }
  };

  useEffect(() => {
    if (interviewUrlId) {
      const interview = interviews?.find((interview) => interview.id === interviewUrlId);
      setSelectedInterview(interview);
    } else {
      setSelectedInterview(undefined);
    }
  }, [interviewUrlId, interviews]);

  return (
    <div className={'relative flex w-full flex-1 overflow-auto'}>
      <Queue
        items={getItems()}
        param1={'personName'}
        param2={isAdmin ? 'interviewerName' : undefined}
        dateParam={'date'}
        selectedItemId={selectedInterview?.id}
        onSelect={handleItemSelect}
        searchEnabled={true}
        sortParam={'date'}
        sortDirection={'asc'}
        dropDownEnabled={isMobile}
        openDropDown={isQueueDropdownOpen}
        onDropDownClose={() => setQueueDropdownOpen(false)}
        {...getQueueConfiguration()}
      />

      <InterviewPageContent
        selectedInterview={selectedInterview}
        isMobile={isMobile}
        setQueueDropdownOpen={setQueueDropdownOpen}
      />
    </div>
  );
};
