import { usePatchPersonMutation } from '@/__generated__/graphql';

export const usePersons = () => {
  const [patchPersonMutation, { loading: patchLoading }] = usePatchPersonMutation({});

  return {
    patchPersonMutation,
    mutationLoading: patchLoading,
  };
};
