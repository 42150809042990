import { Interview } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';

interface Props {
  interview: Interview;
}

export const ApplicationDetails = ({ interview }: Props) => {
  const person = interview.person;

  const details = [
    { title: 'Archetype', value: person.archetype },
    { title: 'Accomplishment', value: person.accomplishments },
    { title: 'Work Blurb', value: person.work_description },
    { title: 'Interest', value: person.interests },
    { title: 'Top 3 Words', value: person.three_words },
    { title: 'Dinner Guest', value: person.application_dinner_guest },
  ];

  return <DetailTableSection title={'Application'} details={details} />;
};
