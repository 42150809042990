import {
  FilterPanelContent,
  IFilter,
  IFilterCondition,
} from '@/components/FilterPanel/FilterPanelContent';
import Popover from '@/components/Popover';
import Button from '@/components/basic/buttons/Button';

type Props = {
  filterKeyValues: IFilter[];
  filters: IFilterCondition[];
  setFilters: (conditions: IFilterCondition[]) => void;
};

export const FilterPanel = ({ filters, setFilters, filterKeyValues }: Props) => {
  const completeFilters = filters.filter((filter) => filter.field && filter.value);
  return (
    <Popover
      horizontal={'left'}
      triggerAction={'click'}
      content={
        <FilterPanelContent
          filterKeyValues={filterKeyValues}
          filters={filters}
          setFilters={setFilters}
        />
      }
      trigger={
        <Button color={'neutral'} variant={'link'}>
          Filters {completeFilters.length ? `(${completeFilters.length})` : ''}
        </Button>
      }
    />
  );
};
