import { InterviewFilterInput, useGetInterviewsQuery } from '@/__generated__/graphql';

export const useInterviews = (filters?: InterviewFilterInput) => {
  const {
    loading: isLoading,
    data: interviews,
    error,
    refetch,
  } = useGetInterviewsQuery({ variables: { filters: filters || {} } });

  return {
    isLoading,
    interviews: interviews?.getInterviews || [],
    error,
    refetchInterviews: refetch,
  };
};
