import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { StarIcon as StarIconFilled } from '@heroicons/react/24/solid';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputLabel } from '@/components/basic/inputs/InputLabel';

interface Props {
  maxStars?: number;
  required?: boolean;
  disabled?: boolean;
  label: string;
  value?: number;
  name?: string;
  onChange?: (value: number) => void;
  size?: 'sm' | 'md' | 'lg';
  labelAction: ReactNode;
  containerClassName?: HTMLDivElement['className'];
}

export const StarInput = ({
  maxStars = 5,
  disabled,
  label,
  value,
  onChange,
  name,
  size = 'md',
  labelAction,
  required,
  containerClassName = '',
}: Props) => {
  const inputSize = size === 'sm' ? 'w-4 h-4' : size === 'lg' ? 'w-8 h-8' : 'w-6 h-6';

  const handleClick = (rating: number) => {
    if (disabled) {
      return;
    }

    if (onChange) {
      onChange({ target: { value: rating, name } } as any);
    }
  };

  return (
    <div className={twMerge('relative', containerClassName)}>
      <InputLabel labelAction={labelAction} label={label} required={required} />
      <div className={'relative flex w-max pl-3'}>
        {new Array(maxStars).fill(0).map((_, i) => {
          const filledStar = (value || -1) >= i + 1;
          const StarIcon = filledStar ? StarIconFilled : StarIconOutline;

          return (
            <StarIcon
              key={i}
              onClick={() => handleClick(i + 1)}
              className={`cursor-pointer ${inputSize} ${disabled ? 'text-gray-600' : filledStar ? 'text-yellow-500' : 'hover:text-yellow-500'}`}
            />
          );
        })}
        {required && !value ? (
          <input
            type={'checkbox'}
            required={true}
            className={'absolute right-1/2 top-0 opacity-0'}
          />
        ) : null}
      </div>
    </div>
  );
};
