import { ReactNode } from 'react';

import { SectionWithHeader } from '@/components/sections/SectionWithHeader';

interface Props {
  title: string;
  details: {
    title: string;
    value?: string | number | null | ReactNode;
  }[];
}

export const DetailTableSection = ({ details, title }: Props) => {
  return (
    <SectionWithHeader title={title}>
      {details.map((detail, i) => (
        <Detail key={i} title={detail.title} value={detail.value} />
      ))}
    </SectionWithHeader>
  );
};

const Detail = ({
  title,
  value,
}: {
  title: string;
  value?: string | number | null | ReactNode;
}) => {
  return (
    <div className={'item flex border-b border-gray-500 text-sm last:rounded-b-lg last:border-b-0'}>
      <h6
        className={
          'w-[100px] min-w-[100px] overflow-hidden text-ellipsis px-2 py-2 font-semibold tracking-wider xl:min-w-[200px]'
        }
      >
        {title}
      </h6>
      <div className={'block min-h-full w-[1px] bg-gray-500'} />
      <div className={'flex-1 overflow-hidden break-words px-2 py-2'}>{value}</div>
    </div>
  );
};
