import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

type IParam = {
  label: string;
  value: number | string | ReactNode;
};

export type IKanbanCard<T> = {
  id: string;
  title: string;
  param1?: IParam;
  param2?: IParam;
  param3?: IParam;
  original?: T;
  onItemClick?: (item: T) => void;
};

export const KanbanCard = <T,>({
  id,
  title,
  param1,
  param2,
  param3,
  onItemClick,
  original,
}: IKanbanCard<T>) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
    data: { type: 'card', details: { id, title, param1, param2, param3 } },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const params = [param1, param2, param3].filter((p) => p !== undefined);

  return (
    <div
      className={`m-2 rounded border border-gray-300 bg-white p-2 shadow ${!isDragging && 'transition-colors hover:bg-gray-100 active:bg-gray-200'} `}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      onClick={original && onItemClick && !isDragging ? () => onItemClick?.(original) : undefined}
    >
      <div id={id} className={'flex flex-col gap-1'}>
        <h6 className={'text-xl font-bold tracking-wide'}>{title}</h6>

        {params.map((param) => (
          <div className={'flex flex-col'} key={param?.label}>
            <p className={'text-sm font-medium text-gray-600'}>{param?.label}</p>
            <p className={'font-semibold'}>{param?.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
