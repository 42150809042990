import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { NavigationLink } from '@/components/layouts/NavigationLayout/components/SideDrawer/NavigationLink';

interface Props {
  name: string;
  icon?: any;
  onClick?: () => void;
  active?: boolean;
}

export const NavigationDropdownLink = ({ name, icon, onClick, active }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <NavigationLink name={name} icon={isHovering && !active ? ChevronDownIcon : icon} />
    </div>
  );
};
